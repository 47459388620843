import useCurrentUser from "Organiser/useCurrentUser";
import userGroupsForAccessLevel from 'Organiser/userGroupsForAccessLevel'
import MovementSearchSelect from './Partials/MovementSearchSelect'

export default function ChecklistNew() {
  const act = useAct();
  const history = useHistory();
  const { user } = useCurrentUser();
  const selected = useSelector(s => s.organisation.selected);
  const [userMode, setUserMode] = useState('user_groups')
  const [movementSearchId, setMovementSearchId] = useState(null)
  const [hiddenAudienceId, setHiddenAudienceId] = useState(null)
  const [error, setError] = useState('')
  const { checklistId } = useParams()
  const mode = checklistId ? 'editing' : 'creating'
  const checklist = useSelector(s => s.checklists.entities[checklistId] || {})

  useEffect(() => {
    if (!checklistId) return
    act.organisation.loadChecklistSelections(checklistId)
    act.checklists.load(checklistId)
  }, [checklistId])

  useEffect(() => {
    if (!checklistId) return

    setValue("name", checklist.name)
    setValue("userGroups", checklist.userGroups)
    setUserMode(checklist.userMode)
    setMovementSearchId(checklist.movementSearchId)
    setHiddenAudienceId(checklist.hiddenAudienceId)
    setValue("hidden", checklist.hidden)
  }, [JSON.stringify(checklist)]);


  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userGroups: ['activists'],
      userMode: 'user_groups',
      hidden: false
    }
  });

  if (!user) {
    return null;
  }

  function create({ name, hidden, userGroups }) {
    setError('')
    if (userMode === 'movement_search' && !movementSearchId) {
      setError("You need to select an audience from Communicator")
      return
    }

    const params = { name, userGroups, userMode, movementSearchId, hiddenAudienceId, hidden }

    if (checklistId) {
      return act.checklist.save(checklistId, params, selected).then(response => {
        history.push(`/checklists/${checklistId}`)
      })
    } else {
      return act.checklist.create(params, selected).then(response => {
        history.push(`/checklists/${response.data.id}`);
      })
    }
  }
  const userGroups = userGroupsForAccessLevel(user.accessLevel)

  function handleUserMode(userMode) {
    setError('')
    setUserMode(userMode)
  }

  return (
    <div>
      <h2 className="mb-2">Add new checklist</h2>
      <div className="box p-4">
        <form onSubmit={handleSubmit(create)}>
          <div className='group'>
            <label>Checklist name</label>
            <input className='wider' type='text' {...register("name", { required: true })} />
            {errors.name && <div>You must enter a name</div>}
          </div>

          <div className='group'>
            <label>Users</label>

            <div>
              <div>How do you want to choose who can complete the checklist?</div>

              <div className='mb-2 mt-2'>
                <a className={`button mr-1 ${userMode === 'user_groups' ? 'bold' : ''}`} onClick={() => handleUserMode('user_groups')}>User Groups</a>
                <a className={`button mr-1 ${userMode === 'movement_search' ? 'bold' : ''}`} onClick={() => handleUserMode('movement_search')}>Communicator Audience</a>
              </div>
            </div>

            <div className='group'>
              <label>Hide this checklist from being taken</label>
              <p className='small smaller dark-grey'>Rep dashboards will still show for reps, even if the checklist is hidden for members</p>
              <input type='checkbox' {...register("hidden")} />

              <p className='small smaller dark-grey'>You can optionally only hide this checklist for people who are in an audience, by picking a Movement audience. This will be refreshed every few hours</p>
              <MovementSearchSelect value={hiddenAudienceId} onChange={v => setHiddenAudienceId(v)} />
            </div>

            <div className='group'>
              <label>Assign to</label>
              { userMode === 'user_groups' && <div>
                { userGroups.map(({ slug, label }) => <label>
                  <input className='wider' type="checkbox" value={slug} {...register("userGroups", { required: true })} />
                  { label }
                </label>) }
              </div> }
            </div>

            { userMode === 'movement_search' && <div>
              <MovementSearchSelect value={movementSearchId} onChange={v => setMovementSearchId(v)} />
            </div> }

            {errors.userGroups && <div>You must choose users</div>}
          </div>

          <button className='button primary'>{ checklistId ? 'Save' : 'Create' }</button>
          { error && <div>{ error }</div>}
        </form>
      </div>
    </div>
  );
};
